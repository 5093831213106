import React, { useRef } from 'react'
import { Stack, Button } from '@mui/material'

const FilterButton = ({ filterLabel, isSelected, onClick, count, buttonRef }) => (
  <Button
    variant={'outlined'}
    onClick={onClick}
    sx={theme => ({
      color: isSelected ? theme.palette.text.primary : theme.palette.text.secondary,
      fontSize: theme.typography.h6.fontSize,
      paddingX: theme.spacing(2),
      paddingY: theme.spacing(1),
      whiteSpace: 'nowrap',
      minWidth: 'auto',
      '&.Mui-focused, &:focus': {
        borderColor: theme.palette.primary.main,
        borderWidth: '2px',
        outline: 'none',
      },
    })}
    ref={buttonRef}
  >
    {`${filterLabel} (${count})`}
  </Button>
)

const PhotoFilters = ({
  photoCount,
  photoFilters,
  selectedPhotoFilter,
  setFilter,
  isMobileDevice,
}) => {
  const buttonRefs = useRef({})

  const centerSelectedFilterButton = filter => {
    if (buttonRefs.current[filter]) {
      buttonRefs.current[filter].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      })
    }
  }
  const handleSelectFilter = filter => {
    setFilter(filter)
    centerSelectedFilterButton(filter)
  }

  const onClickAll = () => {
    setFilter(null)
    centerSelectedFilterButton('All')
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={theme => ({
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        display: 'flex',
        overflowX: 'auto',
        zIndex: 2000,
        padding: 10,
        backgroundColor: theme.colors.alpha.white[100],
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: theme.colors.alpha.black[30],
        paddingX: isMobileDevice ? theme.spacing(1) : theme.spacing(8),
        paddingY: theme.spacing(1),
        scrollbarWidth: 'none', // Hide scrollbar for Firefox
        '&::-webkit-scrollbar': {
          display: 'none', // Hide scrollbar for Chrome, Safari, and Edge
        },
      })}
    >
      <FilterButton
        filterLabel={'All'}
        isSelected={!selectedPhotoFilter}
        onClick={onClickAll}
        count={photoCount}
        buttonRef={el => (buttonRefs.current['All'] = el)}
      />
      {photoFilters.map(filter => (
        <FilterButton
          key={filter.value}
          filterLabel={filter.value}
          isSelected={selectedPhotoFilter === filter.value}
          onClick={() => handleSelectFilter(filter.value)}
          count={filter.count}
          buttonRef={el => (buttonRefs.current[filter.value] = el)}
        />
      ))}
    </Stack>
  )
}

export default PhotoFilters
