import React from 'react'
import { Box, ImageList, ImageListItem, Skeleton } from '@mui/material'
import Lightbox from './Lightbox'
import PhotoFilters from './PhotoFilters'
import { usePhotoList } from '../../../../CustomHooks/usePhotoList'

const DesktopPhotoList = ({ photos, onClose, photoIndex, setPhotoIndex, onClickListPhoto }) => {
  const {
    itemRefs,
    selectedPhotoFilter,
    photoFilters,
    loadedImages,
    filteredPhotos,
    setFilter,
    handleImageLoad,
  } = usePhotoList(photos, photoIndex, setPhotoIndex)

  const getItemSize = photoIndex => {
    const isFinalPhoto = photoIndex + 1 === filteredPhotos.length
    const isSecondLastPhoto = photoIndex + 2 === filteredPhotos.length
    const finalRowPhotoCount = filteredPhotos.length % 3

    // Handle final row cases (1 or 2 photos remaining)
    if (finalRowPhotoCount === 1 && isFinalPhoto) {
      return { rows: 4, cols: 6 } // Single photo takes full width
    }
    if (finalRowPhotoCount === 2 && (isFinalPhoto || isSecondLastPhoto)) {
      return { rows: 3, cols: 3 } // Two photos split evenly
    }
    // Large photos that alternate from left to right in the grid
    const isLargePhoto = photoIndex % 6 === 0 || (photoIndex - 4) % 6 === 0
    if (isLargePhoto) {
      return { rows: 4, cols: 4 }
    }
    // Default small photo
    return { rows: 2, cols: 2 }
  }

  return (
    <Lightbox isOpen={true} onClose={onClose}>
      <Box position="relative" id="desktop-photo-list">
        <ImageList
          variant="quilted"
          rowHeight={150}
          cols={6}
          gap={8}
          sx={{
            width: '100%',
            height: filteredPhotos.length > 3 ? '100vh' : 'auto',
            padding: theme => theme.spacing(9),
          }}
        >
          {filteredPhotos.map((photo, index) => {
            const { rows, cols } = getItemSize(index)

            return (
              <ImageListItem
                key={photo.node.photo_url}
                cols={cols}
                rows={rows}
                ref={el => (itemRefs.current[index] = el)}
                sx={{ position: 'relative' }}
              >
                {!loadedImages[photo.node.photo_url] && (
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                )}
                <img
                  loading="lazy"
                  src={photo.node.photo_url}
                  alt={photo.node.room_name}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  onClick={() => onClickListPhoto(photo.originalIndex || index)}
                  onLoad={() => handleImageLoad(photo.node.photo_url)}
                />
              </ImageListItem>
            )
          })}
        </ImageList>
        {photoFilters.length > 0 && (
          <PhotoFilters
            photoCount={photos.edges.length}
            photoFilters={photoFilters}
            selectedPhotoFilter={selectedPhotoFilter}
            setFilter={setFilter}
          />
        )}
      </Box>
    </Lightbox>
  )
}

export default DesktopPhotoList
