import { useState, useRef, useMemo, useEffect } from 'react'

export const usePhotoList = (photos, photoIndex, setPhotoIndex, isLandscape) => {
  const itemRefs = useRef([])
  const [selectedPhotoFilter, setPhotoFilter] = useState()
  const [shouldScrollToPhotoIndex, setScrollToPhotoIndex] = useState(true)
  const [loadedImages, setLoadedImages] = useState({})

  const photoFilters = useMemo(() => {
    let filterSet = [...new Set(photos.edges.map(image => image.node.room_name))]
    filterSet = filterSet.filter(roomName => roomName !== null)

    return filterSet.map(filter => ({
      value: filter,
      count: photos.edges.filter(image => image.node.room_name === filter).length,
    }))
  }, [photos])

  useEffect(() => {
    // Scroll to the specified photoIndex when the component mounts
    if (!shouldScrollToPhotoIndex) return
    const imageUrl = photos.edges[photoIndex]?.node.photo_url
    if ((loadedImages[imageUrl] && itemRefs.current[photoIndex]) || isLandscape) {
      itemRefs.current[photoIndex].scrollIntoView({
        behavior: 'auto',
        block: isLandscape ? 'start' : 'center',
        inline: isLandscape ? 'center' : 'start',
      })
      setScrollToPhotoIndex(false)
    }
  }, [photoIndex, loadedImages, shouldScrollToPhotoIndex])

  const setFilter = filter => {
    setPhotoFilter(filter)
    scrollToTopOfPhotoList()
  }

  const scrollToTopOfPhotoList = () => {
    setPhotoIndex(0)
    setScrollToPhotoIndex(true)
  }

  const handleImageLoad = photoUrl => {
    setLoadedImages(prevState => ({
      ...prevState,
      [photoUrl]: true,
    }))
  }

  const filteredPhotos = selectedPhotoFilter
    ? photos.edges
        .map((photo, index) => ({ ...photo, originalIndex: index })) // originalIndex helps set the index of a selected photo (photoIndex)
        .filter(photo => photo.node.room_name?.match(selectedPhotoFilter))
    : photos.edges

  return {
    itemRefs,
    selectedPhotoFilter,
    photoFilters,
    loadedImages,
    filteredPhotos,
    setFilter,
    handleImageLoad,
  }
}
