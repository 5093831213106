import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery, Box } from '@mui/material'
import { gql } from '@apollo/client'
import PhotoLightbox from './PhotoLightbox'
import PhotoGrid from './Photos/PhotoGrid'
import MobilePhotoCarousel from './Photos/MobilePhotoCarousel'
import MobilePhotoList from './Photos/PhotoList/MobilePhotoList'
import DesktopPhotoList from './Photos/PhotoList/DesktopPhotoList'

export const LISTING_PHOTOS_FRAGMENT = gql`
  fragment listingPhotosFragment on Property {
    id
    photos {
      edges {
        node {
          id
          photo_url
          room_name
        }
      }
    }
  }
`

const LightboxState = {
  PHOTO_LIST: 'PHOTO_LIST',
  PHOTO_CAROUSEL: 'PHOTO_CAROUSEL',
  NONE: 'NONE',
}

const ListingPhotos = ({ photos }) => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [lightboxState, setLightboxState] = useState(LightboxState.NONE)
  const theme = useTheme()
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'))

  const onClose = () => {
    setLightboxState(LightboxState.NONE)
    setPhotoIndex(0)
  }

  const openPhotoList = index => {
    setPhotoIndex(index)
    setLightboxState(LightboxState.PHOTO_LIST)
  }

  const openPhotoCarousel = index => {
    setPhotoIndex(index)
    setLightboxState(LightboxState.PHOTO_CAROUSEL)
  }

  return (
    <>
      {isSmallerThanMd ? (
        <>
          {lightboxState === LightboxState.PHOTO_LIST && (
            <MobilePhotoList
              photos={photos}
              photoIndex={photoIndex}
              setPhotoIndex={setPhotoIndex}
              onClose={onClose}
            />
          )}
          <MobilePhotoCarousel photos={photos} handlePhotoClick={openPhotoList} />
        </>
      ) : (
        <>
          {lightboxState === LightboxState.PHOTO_CAROUSEL && (
            <PhotoLightbox
              photos={photos}
              photoIndex={photoIndex}
              isLightboxOpen={lightboxState === LightboxState.PHOTO_CAROUSEL}
              onCloseLightbox={onClose}
            />
          )}
          {lightboxState === LightboxState.PHOTO_LIST && (
            <DesktopPhotoList
              photos={photos}
              photoIndex={photoIndex}
              setPhotoIndex={setPhotoIndex}
              onClickListPhoto={openPhotoCarousel}
              onClose={onClose}
            />
          )}
          <Box className="photo-grid">
            <PhotoGrid photos={photos} onPhotoClick={openPhotoList} />
          </Box>
        </>
      )}
    </>
  )
}

export default ListingPhotos
