import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'
import { Box, Typography } from '@mui/material'

const MobilePhotoCarousel = ({ photos, handlePhotoClick }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isLandscape, setIsLandscape] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth > window.innerHeight)
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Set the initial orientation

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Box position="relative" id="mobile-photo-carousel">
      <Swiper
        slidesPerView={1}
        id="swiper"
        style={{ height: isLandscape ? '65vh' : '35vh' }}
        onSlideChange={swiper => setCurrentIndex(swiper.activeIndex)}
      >
        {photos.edges.map((photo, index) => {
          const node = photo.node
          return (
            <SwiperSlide
              key={node.photo_url}
              style={{
                ...(isLandscape && {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }),
              }}
            >
              <Box
                loading="lazy"
                onClick={() => handlePhotoClick(currentIndex)}
                component="img"
                src={node.photo_url}
                sx={theme => ({
                  width: '100%',
                  objectFit: isLandscape ? 'contain' : 'cover',
                  height: isLandscape ? '65vh' : '35vh',
                  backgroundColor: theme.colors.alpha.black[10],
                })}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>

      {/* Display the current slide index and total number of slides. ie: "3 of 6" */}
      <Box
        sx={theme => ({
          zIndex: 1,
          position: 'absolute',
          bottom: theme.spacing(1),
          right: theme.spacing(1),
          bgcolor: theme.colors.alpha.black[70],
          px: theme.spacing(2),
          py: theme.spacing(1),
          borderRadius: '8px',
        })}
      >
        <Typography
          variant="body1"
          fontWeight="bold"
          sx={theme => ({
            color: theme.palette.common.white,
          })}
        >
          {currentIndex + 1} of {photos.edges.length}
        </Typography>
      </Box>
    </Box>
  )
}

export default MobilePhotoCarousel
