import React, { useState, useEffect } from 'react'
import { Box, IconButton, Fade } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const Lightbox = ({ children, isOpen, onClose }) => {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight)

  // Adjust the lightbox height when the screen resizes
  useEffect(() => {
    const handleResize = () => setViewportHeight(window.innerHeight)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <Fade in={isOpen} timeout={500}>
      <Box
        sx={theme => ({
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: viewportHeight,
          bgcolor: theme.colors.alpha.white[100],
          zIndex: 1300,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        })}
        onClick={onClose}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
          }}
          onClick={e => e.stopPropagation()} // Prevent closing when clicking inside the content
        >
          <IconButton
            id="lightbox-close-button"
            onClick={onClose}
            sx={theme => ({
              position: 'absolute',
              top: theme.spacing(2),
              right: theme.spacing(2),
              borderRadius: '26px',
              color: theme.palette.common.white,
              bgcolor: theme.colors.alpha.black[70],
              '&:hover': {
                bgcolor: theme.colors.alpha.black[50],
              },
              zIndex: 1400,
            })}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
          {children}
        </Box>
      </Box>
    </Fade>
  )
}

export default Lightbox
